import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import {createBrowserHistory} from "history";
import {Provider} from "react-redux";
import {Router} from "react-router-dom";
import {ThemeProvider} from '@material-ui/core/styles';
import store from "./helpers/store";
import atene from "./themes/atene";
import AppRouter from "./routers/AppRouter";

export const history = createBrowserHistory();

ReactDOM.render(
    <Provider store={store}>

        <ThemeProvider theme={atene}>
            <Router history={history}>
                <AppRouter/>
            </Router>
        </ThemeProvider>
    </Provider>
    ,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
