import trackingConstants from "../constants/tracking"
import {getTrackingService} from "../services/tracking";

const getTrackingRequest = () => ({
    type: trackingConstants.GET_TRACKING_REQUEST
})

const getTrackingFailure = (error) => ({
    type: trackingConstants.GET_TRACKING_FAILURE,
    error
})

const getTrackingSuccess = (data) => ({
    type: trackingConstants.GET_TRACKING_SUCCESS,
    data
})

export const getTracking = (id) => {

    return async (dispatch) => {

        dispatch(getTrackingRequest())

        try {

            const response = await getTrackingService(id);

            dispatch(getTrackingSuccess(response.data));

        } catch (e) {
            dispatch(getTrackingFailure(e))
        }

    }

}
