export default [
    {
        id         : 1,
        status     : 'in_preparation',
        title      : 'Preparando tu orden de compra',
        description: 'Recibimos la orden para recolectar tu compra por la tienda. Apenas el vendedor nos confirme la venta, pasaremos a retirar los productos para llevarlos a destino.'
    },
    {
        id         : 2,
        status     : 'on_the_way',
        title      : 'En camino',
        description: 'Tus productos ya están llegando a tu domicilio! A estar atentos a la puerta!'
    },
    {
        id         : 3,
        status     : 'delivered',
        title      : 'Entregado',
        description: 'Entregamos con éxito tu envío!'
    },
    {
        id         : 4,
        status     : 'returned',
        title      : 'Devuelto',
        description: 'Los productos que no hemos podido entregarte ya van de regreso a la tienda.'
    },
    {
        id         : 5,
        status     : 'first_visit',
        title      : 'Primera visita',
        description: 'Hemos intentado la entrega pero no atendieron al llamado en la puerta. Volveremos nuevamente en las próximas 72hs hábiles.'
    },
    {
        id         : 6,
        status     : 'withdrawed',
        title      : 'Retirado',
        description: 'Ya tenemos en nuestro poder tus productos. Comienzan los procesos de distribución.'
    },
    {
        id         : 7,
        status     : 'canceled',
        title      : 'Cancelado',
        description: 'Tu envío ha sido cancelado. Para mayor información, contactate con la tienda.'
    },
    {
        id         : 8,
        status     : 'available_for_pickup',
        title      : 'Disponible para retiro en sucursal',
        description: 'Podes retirar tu compra en la sucursal indicada.'
    },
    {
        id         : 9,
        status     : 'second_visit',
        title      : 'Segunda visita',
        description: 'Nuevamente hemos intentado entregarte tu compra y no logramos contactarte. Por favor, comunicarse con la tienda para resolver su envío.'
    },
    {
        id         : 10,
        status     : 'received',
        title      : 'Recibido en planta (proceso de clasificación)',
        description: 'Tus productos han sido ingresados a nuestra planta de trasbordo. Procederemos a coordinar la entrega.'
    },
    {
        id         : 11,
        status     : 'on_destination_branch',
        title      : 'En sucursal de destino',
        description: 'Tus productos arribaron a nuestra sucursal de trasbordo en la provincia de destino. Comienza el tramo final hasta el domicilio de destino.'
    },
    {
        id         : 12,
        status     : 'ready_to_dispatch',
        title      : 'En proceso de clasificación',
        description: 'Tu envío está siendo organizado en nuestro centro para salir a distribución.'
    },
    {
        id         : 13,
        status     : 'ready_to_pickup',
        title      : 'Preparando tu orden de compra',
        description: 'Recibimos la orden para recolectar tu compra por la tienda. Apenas el vendedor nos confirme la venta, pasaremos a retirar los productos para llevarlos a destino.'
    },
    {
        id         : 14,
        status     : 'in_travel',
        title      : 'En viaje',
        description: 'Tu envío está en camino a la sucursal de destino.'
    }
];
