import {createStore, combineReducers, applyMiddleware, compose} from "redux";
import thunkMiddleware from 'redux-thunk';
import {createLogger} from 'redux-logger';

import authReducer from '../reducers/auth';
import trackingReducer from '../reducers/tracking';
import dialogsReducer from '../reducers/dialogs';
import trackingQueryReducer from '../reducers/trackingQuery';

const loggerMiddleware = createLogger();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default createStore(
    combineReducers({
        auth         : authReducer,
        tracking     : trackingReducer,
        dialogs      : dialogsReducer,
        trackingQuery: trackingQueryReducer
    }),
    composeEnhancers(applyMiddleware(thunkMiddleware, loggerMiddleware))
);
