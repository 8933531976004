import dialogsConstants from '../constants/dialogs';

const dialogsReducerInitialState = {
    openTrackingQueryDialog: false
};

export default (state = dialogsReducerInitialState, action) => {

    switch (action.type) {

        case dialogsConstants.OPEN_TRACKING_QUERY_DIALOG:

            return {
                ...state,
                openTrackingQueryDialog: true
            };

        case dialogsConstants.CLOSE_TRACKING_QUERY_DIALOG:

            return {
                ...state,
                openTrackingQueryDialog: false
            };

        default:

            return state;

    }

}
