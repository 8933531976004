import authConstants from "../constants/auth";

const authReducerInitialState = {
    request: false,
    error  : null,
    data   : {}
};

export default (state = authReducerInitialState, action) => {

    switch (action.type) {

        case authConstants.LOGIN_REQUEST:

            return {
                ...state,
                request: true,
                error  : null
            };

        case authConstants.LOGIN_FAILURE:

            return {
                ...state,
                request: false,
                error  : action.error
            };

        case authConstants.LOGIN_SUCCESS:

            return {
                ...state,
                request: false,
                error  : null,
                data   : action.data
            };

        default:

            return state;

    }

}