import {Route, Switch} from 'react-router-dom';
import React from "react";
import Tracking from "../views/Tracking";
import Welcome from "../views/Welcome";

const AppRouter = () => (

    <Switch>

        <Route path={"/"} exact={true} component={Welcome}/>
        <Route path={"/:id"} exact={true} component={Tracking}/>

    </Switch>

);

export default AppRouter;
