import React from 'react';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({

    footer: {
        color     : '#FFFFFF',
        textAlign : 'left',
        position  : 'relative',
        marginTop : '30vh',
        marginLeft: '1vw'
    }

}));

export default () => {

    const classes = useStyles();

    return (
        <div className={classes.footer}>
            ATENÉ LOGISTIC | Todos los derechos reservados
        </div>
    );

}
