import React, {useCallback, useEffect, useState} from 'react';
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import DesktopMenu from "../components/DesktopMenu";
import {useDispatch, useSelector} from "react-redux";
import {getTracking} from "../actions/tracking";
import {login} from "../actions/auth";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepContent from "@material-ui/core/StepContent";
import {
    Button,
    Container,
    Hidden,
    StepConnector,
    Typography
} from "@material-ui/core";
import StepLabel from "@material-ui/core/StepLabel";
import {loadCSS} from "fg-loadcss";
import Icon from "@material-ui/core/Icon";
import makeStyles from "@material-ui/core/styles/makeStyles";
import LinearProgress from "@material-ui/core/LinearProgress";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import messages from "../constants/messages";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import {Check} from "@material-ui/icons";
import clsx from "clsx";
import PropTypes from 'prop-types';
import StatusImage from "../components/StatusImage";
import {history} from "../index";
import {openTrackingQueryDialog} from "../actions/dialogs";
import TrackingQueryDialog from "../components/TrackingQueryDialog";

const useStyles = makeStyles((theme) => ({
    searchForm          : {
        marginTop   : '2em',
        marginBottom: '2em'
    },
    stepIconRoot        : {
        width: '2em',
        color: theme.palette.action.disabled
    },
    stepIconRootActive  : {
        width: '2em',
        color: theme.palette.primary.main
    },
    progressContainer   : {
        marginTop: '2em'
    },
    media               : {
        height: 140,
        width : 140
    },
    cardDetailsContainer: {
        display       : 'flex',
        flexDirection : 'row',
        justifyContent: 'space-between',
        alignItems    : 'center',
        marginTop     : '1em'
    },
    cardDetailsProduct  : {
        display      : 'flex',
        flexDirection: 'column'
    },
    cardActions         : {
        display       : 'flex',
        justifyContent: 'flex-end'
    },
    paper               : {
        padding: '2em'
    },
    button              : {
        color     : '#FFFFFF',
        background: `linear-gradient(90deg, rgba(250,91,84,1) 0%, rgba(252,133,63,1) 100%)`,
    },
    toolbar             : {
        display       : 'flex',
        justifyContent: 'space-between'
    },
    questionsButton     : {
        // width: '20%',
        // backgroundColor: `rgb(0,0,0,0.4)`,
        // borderRadius: '25px',
        // borderColor: '#FFFFFF',
        // borderWidth: '1px',
        // borderStyle: 'solid',
        // color: '#FFFFFF',
        // textTransform: 'none'
    },
}));

export default (props) => {

    const handleOpenTrackingQueryDialog = () => {
        dispatch(openTrackingQueryDialog())
    }

    const QontoConnector = withStyles((props) => ({
        alternativeLabel: {
            top  : 10,
            left : 'calc(-50% + 16px)',
            right: 'calc(50% + 16px)'
        },
        active          : {
            '& $line': {
                borderColor: '#A6CE36'
            }
        },
        completed       : {
            '& $line': {
                borderColor: '#A6CE36'
            }
        },
        line            : {
            borderColor   : '#eaeaf0',
            borderTopWidth: 3,
            borderRadius  : 1
        }
    }))(StepConnector);

    const useQontoStepIconStyles = makeStyles({
        root     : {
            color     : '#eaeaf0',
            display   : 'flex',
            height    : 22,
            alignItems: 'center'
        },
        active   : {
            color: '#A6CE36'
        },
        circle   : {
            width          : 8,
            height         : 8,
            borderRadius   : '50%',
            backgroundColor: 'currentColor'
        },
        completed: {
            color   : '#A6CE36',
            zIndex  : 1,
            fontSize: 18
        }
    });

    function QontoStepIcon(props) {
        const classes = useQontoStepIconStyles();
        const {active, completed} = props;

        return (
            <div
                className={clsx(classes.root, {
                    [classes.active]: active
                })}
            >
                {completed ? <Check className={classes.completed}/> : <div className={classes.circle}/>}
            </div>
        );
    }

    QontoStepIcon.propTypes = {
        /**
         * Whether this step is active.
         */
        active: PropTypes.bool,
        /**
         * Mark the step as completed. Is passed to child components.
         */
        completed: PropTypes.bool
    };

    const useClasses = useStyles();
    const [activeStep, setActiveStep] = useState(0);
    const [activeHeaderStep, setActiveHeaderStep] = useState(0);
    const dispatch = useDispatch();

    const {request, error, data} = useSelector(state => state.tracking);
    const {request: loginRequest, error: loginError} = useSelector(state => state.auth);

    const init = useCallback(async () => {
        await dispatch(getTracking(props.match.params.id));
    }, [dispatch]);

    useEffect(() => {
        init();
    }, [init]);

    useEffect(() => {
        const node = loadCSS(
            'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
            document.querySelector('#font-awesome-css')
        );

        return () => {
            node.parentNode.removeChild(node);
        };
    }, []);

    useEffect(() => {

        if (!data.length) {
            return;
        }

        const currentState = data[0];
        const smallStepper = currentState?.location === 'BUENOS AIRES' || currentState?.location === 'CABA' ? headerStepperStepsInsideBuenosAires : headerStepperStepsOutsideBuenosAires;

        let activeStepFound = false;
        let statusIndex = 0;
        let index = 1;

        while (!activeStepFound) {

            if (!data[statusIndex]) {
                index = 1;
                activeStepFound = true;
            }

            index = smallStepper.findIndex(step => step.status === data[statusIndex].status);

            if (index === -1) {
                statusIndex++;
            } else {
                activeStepFound = true;
            }
        }

        setActiveHeaderStep(index);

    }, [data]);

    const ColorlibStepIcon = (props) => {

        const icons = {
            'in_preparation'       : <Icon className={"fas fa-box-open fa-2x"} classes={{...props.classes}}/>,
            'on_the_way'           : <Icon className={"fas fa-shipping-fast fa-2x"} classes={{...props.classes}}/>,
            'delivered'            : <Icon className={"fas fa-check fa-2x"} classes={{...props.classes}}/>,
            'returned'             : <Icon className={"fas fa-history fa-2x"} classes={{...props.classes}}/>,
            'first_visit'          : <Icon className={"fas fa-home fa-2x"} classes={{...props.classes}}/>,
            'withdrawed'           : <Icon className={"fas fa-box fa-2x"} classes={{...props.classes}}/>,
            'canceled'             : <Icon className={"fas fa-window-close fa-2x"} classes={{...props.classes}}/>,
            'available_for_pickup' : <Icon className={"fas fa-warehouse fa-2x"} classes={{...props.classes}}/>,
            'second_visit'         : <Icon className={"fas fa-shipping-timed fa-2x"} classes={{...props.classes}}/>,
            'received'             : <Icon className={"fas fa-qrcode fa-2x"} classes={{...props.classes}}/>,
            'on_destination_branch': <Icon className={"fas fa-warehouse fa-2x"} classes={{...props.classes}}/>,
            'ready_to_dispatch'    : <Icon className={"fas fa-boxes fa-2x"} classes={{...props.classes}}/>,
            'ready_to_pickup'      : <Icon className={"fas fa-box-open fa-2x"} classes={{...props.classes}}/>
        };

        return (
            <div>
                {icons[String(props.status)]}
            </div>
        );
    };

    const headerStepperStepsOutsideBuenosAires = [
        {
            name  : 'En preparación de orden',
            status: 'in_preparation',
            id    : 1
        },
        {
            name  : 'En centro de distribución',
            status: 'received',
            id    : 10
        },
        {
            name  : 'Pendiente de despacho',
            status: 'ready_to_dispatch',
            id    : 12
        },
        {
            name  : 'En viaje',
            status: 'in_travel',
            id    : 14
        },
        {
            name  : 'En sucursal de destino',
            status: 'on_destination_branch',
            id    : 11
        },
        {
            name  : 'En camino al domicilio',
            status: 'on_the_way',
            id    : 2
        },
        {
            name  : 'Entregado',
            status: 'delivered',
            id    : 3
        }
    ];

    const headerStepperStepsInsideBuenosAires = [
        {
            name  : 'En preparación de orden',
            status: 'in_preparation',
            id    : 1
        },
        {
            name  : 'En centro de distribución',
            status: 'received',
            id    : 10
        },
        {
            name  : 'En camino al domicilio',
            status: 'on_the_way',
            id    : 2
        },
        {
            name  : 'Entregado',
            status: 'delivered',
            id    : 3
        }
    ];

    const handleActiveStep = (step) => {


        if (step === activeStep) {
            setActiveStep(null);
            return;
        }

        setActiveStep(step);

    };

    const smallStepper = data[0]?.location === 'BUENOS AIRES' || data[0]?.location === 'CABA' ? headerStepperStepsInsideBuenosAires : headerStepperStepsOutsideBuenosAires;

    return (
        <>
            <CssBaseline/>
            <TrackingQueryDialog/>
            <AppBar position={"static"} color={"primary"}>
                <DesktopMenu/>
            </AppBar>

            <Container fixed>

                {
                    request ? (

                        <div className={useClasses.progressContainer}>
                            <LinearProgress/>
                        </div>


                    ) : (

                        data.length ? (


                            <Grid container spacing={2}>

                                <Grid item xs={12} sm={12} md={12} align={"center"}>
                                    <StatusImage
                                        status={data[0]}
                                    />
                                </Grid>

                                <Hidden smDown>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <Paper>
                                            <Stepper alternativeLabel activeStep={activeHeaderStep}
                                                     connector={<QontoConnector/>}>
                                                {
                                                    smallStepper.map((step, key) => (
                                                        <Step key={key}>
                                                            <StepLabel
                                                                StepIconComponent={QontoStepIcon}>
                                                                {step.name}
                                                            </StepLabel>
                                                        </Step>
                                                    ))
                                                }
                                            </Stepper>
                                        </Paper>
                                    </Grid>
                                </Hidden>

                                <Grid item xs={12} sm={12} md={12}>
                                    <Paper className={useClasses.paper}>
                                        <div className={useClasses.toolbar}>
                                            <Typography variant={"caption"}>Orden # {props.match.params.id}</Typography>
                                            <Button
                                                classes={{
                                                    root: useClasses.button
                                                }}
                                                onClick={() => {
                                                    history.push('/')
                                                }}
                                            >
                                                Nueva búsqueda
                                            </Button>
                                        </div>
                                        <Stepper orientation={"vertical"} activeStep={activeStep}>

                                            {
                                                data.map((step, key) => {

                                                    const message = messages.find(message => message.status === step.status);

                                                    return (


                                                        <Step key={key} completed={false}>
                                                            <StepLabel
                                                                StepIconProps={{
                                                                    status: step.status,
                                                                    classes : {
                                                                        root: key ? useClasses.stepIconRoot : useClasses.stepIconRootActive
                                                                    }
                                                                }}
                                                                StepIconComponent={ColorlibStepIcon}>
                                                                {
                                                                    <div>
                                                                        <Typography variant={"h6"}>
                                                                            {message.title}
                                                                            <IconButton onClick={() => {
                                                                                handleActiveStep(key);
                                                                            }}>
                                                                                <ArrowDropDownIcon/>
                                                                            </IconButton>
                                                                        </Typography>
                                                                        <Typography variant={"caption"}>
                                                                            {step.location} | {step.date} - {step.time}
                                                                        </Typography>
                                                                    </div>

                                                                }
                                                            </StepLabel>
                                                            <StepContent>
                                                                <Typography variant={"overline"}>
                                                                    {
                                                                        message && message.description
                                                                    }
                                                                </Typography>
                                                            </StepContent>
                                                        </Step>


                                                    );

                                                })
                                            }

                                        </Stepper>

                                        <div style={{
                                            display       : 'flex',
                                            justifyContent: 'flex-end'
                                        }}>
                                            <Button
                                                classes={{
                                                    root: useClasses.button
                                                }}
                                                onClick={handleOpenTrackingQueryDialog}
                                            >
                                                ¿Alguna consulta sobre tu envío?
                                            </Button>
                                        </div>

                                    </Paper>

                                </Grid>

                            </Grid>


                        ) : (

                            error ? (
                                <Typography variant={"caption"}>
                                    No se encontró el número de tracking
                                </Typography>
                            ) : (
                                <></>
                            )
                        )

                    )
                }


            </Container>

        </>
    );

}
