import React, {useState} from 'react';
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    LinearProgress,
    TextField,
    Typography
} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {closeTrackingQueryDialog} from "../actions/dialogs";
import {makeStyles} from "@material-ui/core/styles";
import {postTrackingQuery} from "../actions/trackingQuery";
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles((theme) => ({
    button: {
        color     : '#FFFFFF',
        background: `linear-gradient(90deg, rgba(250,91,84,1) 0%, rgba(252,133,63,1) 100%)`
    },
    input : {
        marginBottom: '1em'
    }
}));

export default () => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const {openTrackingQueryDialog: open} = useSelector(state => state.dialogs);
    const [trackingQueryForm, setTrackingQueryForm] = useState({});

    const {request, error, success} = useSelector(state => state.trackingQuery);

    const setTrackingQueryFormValue = (name, value) => {
        setTrackingQueryForm({
            ...trackingQueryForm,
            [name]: value
        });
    };

    const handleClose = () => {
        dispatch(closeTrackingQueryDialog());
    };

    const handleSubmit = (e) => {
        dispatch(postTrackingQuery(trackingQueryForm));
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>
                <Typography variant={"overline"}>Comunicate con nosotros</Typography>
                <DialogContent>

                    <TextField
                        classes={{
                            root: classes.input
                        }}
                        required
                        fullWidth={true}
                        label={"Nombre"}
                        name={"name"}
                        onChange={(e) => {
                            setTrackingQueryFormValue('name', e.target.value);
                        }}
                    />

                    <TextField
                        classes={{
                            root: classes.input
                        }}
                        required
                        fullWidth={true}
                        label={"Teléfono"}
                        name={"phone"}
                        onChange={(e) => {
                            setTrackingQueryFormValue('phone', e.target.value);
                        }}
                    />

                    <TextField
                        classes={{
                            root: classes.input
                        }}
                        required
                        fullWidth={true}
                        label={"Correo electrónico"}
                        name={"email"}
                        onChange={(e) => {
                            setTrackingQueryFormValue('email', e.target.value);
                        }}
                    />

                    <TextField
                        classes={{
                            root: classes.input
                        }}
                        required
                        fullWidth={true}
                        label={"Número de trackeo"}
                        name={"tracking_number"}
                        onChange={(e) => {
                            setTrackingQueryFormValue('tracking_number', e.target.value);
                        }}
                    />

                    <TextField
                        classes={{
                            root: classes.input
                        }}
                        required
                        fullWidth={true}
                        label={"Mensaje"}
                        name={"message"}
                        multiline
                        rows={3}
                        onChange={(e) => {
                            setTrackingQueryFormValue('message', e.target.value);
                        }}
                    />

                    {
                        error && error.errors && error.errors.map(error => {

                            return Object.keys(error).map((key, index) => {
                                return <div key={key}>
                                    <Typography variant={"caption"} color={"primary"}>
                                        {error[key]}
                                    </Typography>
                                </div>;
                            });

                        })
                    }

                    {
                        request ? (

                            <LinearProgress/>

                        ) : (

                            <Button
                                variant={"contained"}
                                fullWidth={true}
                                classes={{
                                    root: classes.button
                                }}
                                onClick={handleSubmit}
                            >Enviar</Button>

                        )
                    }

                    {
                        success &&
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginTop: '1em'
                            }}>
                                <CheckIcon color={"primary"}/>
                                <Typography variant={"subtitle2"} style={{
                                    justifyContent: ''
                                }}>
                                    Mensaje enviado. Te contestaremos a la brevedad
                                </Typography>
                            </div>
                    }

                </DialogContent>
            </DialogTitle>
        </Dialog>
    );

}
