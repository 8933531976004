import trackingQueryConstants from '../constants/trackingQuery'
import {postTrackingQueryService} from "../services/trackingQuery";

const postTrackingQueryRequest = () => ({
    type: trackingQueryConstants.POST_TRACKING_QUERY_REQUEST
})

const postTrackingQuerySuccess = () => ({
    type: trackingQueryConstants.POST_TRACKING_QUERY_SUCCESS
})

const postTrackingQueryFailure = (error) => ({
    type: trackingQueryConstants.POST_TRACKING_QUERY_FAILURE,
    error
})

export const postTrackingQuery = (form) => {

    return async (dispatch) => {

        try {

            dispatch(postTrackingQueryRequest());

            const response = await postTrackingQueryService(form);

            dispatch(postTrackingQuerySuccess());

        } catch (error) {
            dispatch(postTrackingQueryFailure(error))
        }

    }

}
