import React from 'react';

import EnPreparacionDeOrden from '../assets/preparacion.svg';
import ConfirmadoRetirar from '../assets/preparacion.svg';
import EnCamino from '../assets/camino.svg';
import Retirado from '../assets/retirado.svg';
import Entregado from '../assets/entregado.svg';
import Devuelto from '../assets/devuelto.svg';
import PrimeraVisita from '../assets/primera-visita.svg';
import SegundaVisita from '../assets/primera-visita.svg';
import Cancelado from '../assets/cancelado.svg';
import Clasificacion from '../assets/clasificacion.svg';
import RecibidoPlanta from '../assets/clasificacion.svg';
import EnViaje from '../assets/clasificacion.svg';

import BuenosAires from '../assets/en-sucursal/Buenos Aires.svg';
import CABA from '../assets/en-sucursal/CABA.svg';
import Catamarca from '../assets/en-sucursal/Catamarca.svg';
import Chaco from '../assets/en-sucursal/Chaco.svg';
import Chubut from '../assets/en-sucursal/Chubut.svg';
import Cordoba from '../assets/en-sucursal/Cordoba.svg';
import Corrientes from '../assets/en-sucursal/Corrientes.svg';
import EntreRios from '../assets/en-sucursal/Entrerios.svg';
import Formosa from '../assets/en-sucursal/Formosa.svg';
import Jujuy from '../assets/en-sucursal/Jujuy.svg';
import LaPampa from '../assets/en-sucursal/Lapampa.svg';
import LaRioja from '../assets/en-sucursal/Larioja.svg';
import Mendoza from '../assets/en-sucursal/Mendoza.svg';
import Misiones from '../assets/en-sucursal/Misiones.svg';
import Neuquen from '../assets/en-sucursal/Neuquen.svg';
import RioNegro from '../assets/en-sucursal/Rionegro.svg';
import Salta from '../assets/en-sucursal/Salta.svg';
import SanJuan from '../assets/en-sucursal/Sanjuan.svg';
import SanLuis from '../assets/en-sucursal/Sanluis.svg';
import SantaCruz from '../assets/en-sucursal/Santacruz.svg';
import SantiagoDelEstero from '../assets/en-sucursal/Santiago.svg';
import TierraDelFuego from '../assets/en-sucursal/Tierradelfuego.svg';
import Tucuman from '../assets/en-sucursal/Tucuman.svg';
import Sucursal from '../assets/en-sucursal/Sucursal.svg';
import {Typography} from "@material-ui/core";

import messages from "../constants/messages";

export default ({status}) => {

    const {status: activeStatus, location} = status;

    const message = messages.find(message => message.status === activeStatus);

    const locationImages = {
        'BUENOS AIRES'       : BuenosAires,
        'CABA'               : CABA,
        'CATAMARCA'          : Catamarca,
        'CHACO'              : Chaco,
        'CHUBUT'             : Chubut,
        'CORDOBA'            : Cordoba,
        'CORRIENTES'         : Corrientes,
        'ENTRE RIOS'         : EntreRios,
        'FORMOSA'            : Formosa,
        'JUJUY'              : Jujuy,
        'LA PAMPA'           : LaPampa,
        'LA RIOJA'           : LaRioja,
        'MENDOZA'            : Mendoza,
        'MISIONES'           : Misiones,
        'NEUQUEN'            : Neuquen,
        'RIO NEGRO'          : RioNegro,
        'SALTA'              : Salta,
        'SAN JUAN'           : SanJuan,
        'SAN LUIS'           : SanLuis,
        'SANTA CRUZ'         : SantaCruz,
        'SANTIAGO DEL ESTERO': SantiagoDelEstero,
        'TIERRA DEL FUEGO'   : TierraDelFuego,
        'TUCUMAN'            : Tucuman,
        'SUCURSAL'           : Sucursal
    };

    const statusImages = {
        'in_preparation'       : EnPreparacionDeOrden,
        'on_the_way'           : EnCamino,
        'delivered'            : Entregado,
        'returned'             : Devuelto,
        'first_visit'          : PrimeraVisita,
        'withdrawed'           : Retirado,
        'canceled'             : Cancelado,
        'available_for_pickup' : '',
        'second_visit'         : SegundaVisita,
        'received'             : RecibidoPlanta,
        'on_destination_branch': '',
        'ready_to_dispatch'    : Clasificacion,
        'ready_to_pickup'      : ConfirmadoRetirar,
        'in_travel'            : EnViaje
    };

    const imageToDisplay = () => {

        if (activeStatus !== 'on_destination_branch') {
            return statusImages[activeStatus];
        }

        if (location === '' || location === null ) {
            return locationImages['SUCURSAL'];
        }

        return locationImages[location];

    };

    return (

        <>
            <img src={imageToDisplay()} alt={"status_img"} style={{height: '300px'}}/>
            <Typography color={"primary"} variant={"h3"}>
                {message.title}
            </Typography>
        </>

    );

}
