import trackingQueryConstants from '../constants/trackingQuery';

const trackingQueryReducerInitialState = {
    request: false,
    error  : null
};

export default (state = trackingQueryReducerInitialState, action) => {

    switch (action.type) {

        case trackingQueryConstants.POST_TRACKING_QUERY_REQUEST:

            return {
                ...state,
                request: true,
                success: false
            };

        case trackingQueryConstants.POST_TRACKING_QUERY_FAILURE:

            return {
                ...state,
                request: false,
                success: false,
                error  : action.error
            };

        case trackingQueryConstants.POST_TRACKING_QUERY_SUCCESS:

            return {
                ...state,
                request: false,
                error  : null,
                success: true
            };

        default:

            return state;

    }

}
