import dialogsConstants from '../constants/dialogs';

export const openTrackingQueryDialog = () => {

    return (dispatch) => {

        dispatch({
            type: dialogsConstants.OPEN_TRACKING_QUERY_DIALOG
        })

    }

}

export const closeTrackingQueryDialog = () => {

    return (dispatch) => {

        dispatch({
            type: dialogsConstants.CLOSE_TRACKING_QUERY_DIALOG
        })

    }

}
