import {createMuiTheme} from "@material-ui/core";

const primaryColor = '#FF8300';
const secondaryColor = '#7C7B7B';

export default createMuiTheme({
    palette: {
        primary  : {
            main: primaryColor
        },
        secondary: {
            main: secondaryColor
        }
    },
    overrides: {
        MuiIcon: {
            colorPrimary: primaryColor
        }
    }
});
