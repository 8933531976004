import trackingConstants from "../constants/tracking";

const trackingReducerInitialState = {
    request: false,
    error  : null,
    data   : []
};

export default (state = trackingReducerInitialState, action) => {

    switch (action.type) {

        case trackingConstants.GET_TRACKING_REQUEST:

            return {
                ...state,
                request: true,
                error  : null
            };

        case trackingConstants.GET_TRACKING_FAILURE:

            return {
                ...state,
                request: false,
                error  : action.error
            };

        case trackingConstants.GET_TRACKING_SUCCESS:

            return {
                ...state,
                request: false,
                error  : null,
                data   : action.data
            };

        default:

            return state;

    }

}