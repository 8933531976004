import React from 'react';
import {Grid, Typography} from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import Logo from "../assets/logo-atene.png";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    img: {
        paddingTop: '0.5em',
        width     : '200px'
    },
    toolbar: {
        background: `linear-gradient(90deg, rgba(250,91,84,1) 0%, rgba(252,133,63,1) 100%)`,
    }
}))

export default () => {

    const styles = useStyles();

    return (
        <Toolbar classes={{
            root: styles.toolbar
        }}>
            <Grid container>
                <Grid item sm={12} align={"left"}>
                    <a href={"http://atenelogistic.com"}>
                        <img src={Logo} alt={"Logo"} className={styles.img}/>
                    </a>
                </Grid>
            </Grid>
        </Toolbar>
    );

}
